import axios from '@/libs/axios'

class DataService {

  send(data) {
    return axios.post("/SendMultipleNotification/send", data);
  }
  
  
}
export default new DataService();