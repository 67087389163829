import { render, staticRenderFns } from "./sendNote.vue?vue&type=template&id=caef6aaa&scoped=true"
import script from "./sendNote.vue?vue&type=script&lang=js"
export * from "./sendNote.vue?vue&type=script&lang=js"
import style0 from "./sendNote.vue?vue&type=style&index=0&id=caef6aaa&prod&scoped=true&lang=css"
import style1 from "./sendNote.vue?vue&type=style&index=1&id=caef6aaa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caef6aaa",
  null
  
)

export default component.exports